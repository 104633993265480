import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '艾灸床管理系统登录',
    },
    component: () => import('../views/login.vue')
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      title: '艾灸床管理系统',
    },
    component: () => import('../views/home.vue')
  },
  {
    path: '**',
    redirect: '/login'
  }
]

const router = new VueRouter({
  routes
})

export default router

import axios from 'axios'
// import { baseUrl } from './env.js'
import {getToken} from '@/api/auth'
import {removeToken} from './auth'
import {Message, Notification, MessageBox} from 'element-ui'
import router from '../router'
// import { Dialog, Toast } from 'vant'
// import store from '@/store'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

const service = axios.create({
    baseURL: "https://a.tcpcat.com:2377", // url = base url + request url
    //baseURL: "http://127.0.0.1:11231", // url = base url + request url
    //baseURL: "https://192.168.1.21:2377", // url = base url + request url
    timeout: 15000 // request timeout
})

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 是否需要设置 token
        if (getToken()) {
            config.headers['Authorization'] = getToken() // 让每个请求携带自定义token
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data

        if (res.status === -1) {
            Message({
                message: res.msg || 'Error',
                type: "info"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -2) {
            Message({
                message: res.msg || 'Error',
                type: "error"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -3) {
            Notification({
                title: '警告',
                message: res.msg,
                type: 'warning',
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -4) {
            Notification({
                title: '错误',
                message: res.msg,
                type: 'error',
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -5) {
            MessageBox.alert(res.msg, "警告", {
                type: "warning"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -6) {
            MessageBox.alert(res.msg, "错误", {
                type: "error"
            })
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -7) {
            removeToken()
            MessageBox.alert("登录验证失效, 请重新登录", "登录失效", {
                type: "error"
            })
            router.push('/login')
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -8) {
            removeToken()
            MessageBox.alert("权限不足, 请重新登录或联系管理员！", "权限不足", {
                type: "error"
            })
            router.push('/login')
            return Promise.reject(new Error(res.msg || 'Error'))
        } else if (res.status === -9) {
            return Promise.reject(new Error(res.msg || 'Error'))
        } else {
            return res
        }
    },
    error => {
        MessageBox.alert("系统错误，请联系技术支持！ (Code:-1)", "系统错误", {
            type: "error"
        })
        return Promise.reject(error)
    }
)

const api = {
    // 登录
    login: (data) => service({
        url: '/api/v1/login',
        method: 'post',
        data
    }),

    // 主页
    card: (data) => service({
        url: '/api/v1/device_card',
        method: 'get',
        params: data

    }),

    // 设备
    enable_device: (data) => service({
        url: '/api/v1/enable_device',
        method: 'post',
        data
    }),
    turn_device: (data) => service({
        url: '/api/v1/turn_device',
        method: 'post',
        data
    }),
    charge_device: (data) => service({
        url: '/api/v1/charge_device',
        method: 'post',
        data
    }),
    device_list: (data) => service({
        url: '/api/v1/device_list',
        method: 'get',
        params: data

    }),
    rename_device: (data) => service({
        url: '/api/v1/rename_device',
        method: 'post',
        data
    }),
    device_detail: (data) => service({
        url: '/api/v1/device_detail',
        method: 'get',
        params: data
    }),
    vu_list: () => service({
        url: '/api/v1/vu_list',
        method: 'get',
    }),
    sel_kids: () => service({
        url: '/api/v1/sel_kids',
        method: 'get',
    }),
    bind_or_not: (data) => service({
        url: '/api/v1/bind_or_not',
        method: 'post',
        data
    }),
    delete_device: (data) => service({
        url: '/api/v1/delete_device',
        method: 'post',
        data
    }),
    deviceorder_export: (data) => service({
        url: '/api/v1/deviceorder_export',
        method: 'post',
        data
    }),
    // 用户
    update_user_info: (data) => service({
        url: '/api/v1/update_user_info',
        method: 'post',
        data
    }),
    delete_user: (data) => service({
        url: '/api/v1/delete_user',
        method: 'post',
        data
    }),
    create_user_default: () => service({
        url: '/api/v1/create_user_default',
        method: 'get',
    }),
    user_list: (data) => service({
        url: '/api/v1/user_list',
        method: 'get',
        params: data
    }),
    charge_user: (data) => service({
        url: '/api/v1/charge_user',
        method: 'post',
        data
    }),
    change_password: (data) => service({
        url: '/api/v1/change_password',
        method: 'post',
        data
    }),
    register: (data) => service({
        url: '/api/v1/register',
        method: 'post',
        data
    }),

    /*moredata: (data) => service({
      url: '/api/v2/thdata',
      method: 'post',
      data
    }),
    home: () => service({
      url: '/api/v2/home',
      method: 'get',
    }),
    wshome: () => service({
      url: '/api/v2/wshome',
      method: 'get',
    }),
    thhome: () => service({
      url: '/api/v2/thhome',
      method: 'get',
    }),
    thlist: (data) => service({
      url: '/api/v2/thlist',
      method: 'get',
      params: data
    }),
    //详情
    thdetail: (data) => service({
      url: '/api/v2/thdetail',
      method: 'get',
      params: data
    }),
    // 删除、修改弹簧
    addth: (data) => service({
      url: '/api/v2/thadd',
      method: 'post',
      data
    }),
    delth: (data) => service({
      url: '/api/v2/thdel',
      method: 'post',
      data
    }),
    getupdate: (data) => service({
      url: '/api/v2/thedit',
      method: 'get',
      params: data
    }),
    setupdate: (data) => service({
      url: '/api/v2/thedit',
      method: 'post',
      data
    }),
    // XML 弹簧列表
    thsearch: (data) => service({
      url: '/api/v2/thsearch',
      method: 'get',
      params: data
    }),
    getxml: (data) => service({
      url: '/api/v2/getxml',
      method: 'get',
      params: data
    }),
    //模具
    modifythws: (data) => service({
      url: '/api/v2/wsmodify',
      method: 'post',
      data
    }),
    getthwslist: () => service({
      url: '/api/v2/wsmodify',
      method: 'get',
    }),
    addws: (data) => service({
      url: '/api/v2/wsadd',
      method: 'post',
      data
    }),
    delws: (data) => service({
      url: '/api/v2/wsdel',
      method: 'post',
      data
    }),
    */

}

export default api
